import { getToken } from '@/utils/cookie'
import request from '@/utils/request'

/**
 * Профиль пользователя
 * @returns {Promise<Profile>}
 */
export function getMyself() {
  return request({
    url: `/api/profile/me/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
