<template>
  <div class="modal">
    <div @click="onClose" class="modal-bg"></div>
    <div class="modal-box-wrapper">
      <div class="modal-box">
        <div class="modal-header">
          <div class="modal-container">
            <slot name="header-all">
              <button
                @click="onClose"
                type="button"
                class="btn btn-close flex-c-c"
              >
                <!-- <span>Отмена</span> -->
                <!-- <svg
                width="25"
                height="25"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.4561 1L1.00021 26.4558"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M1 1L26.4558 26.4558"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg> -->
                <svg
                  width="16"
                  height="28"
                  viewBox="0 0 16 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 1.72791L2 14.2279L14.5 26.7279"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </slot>
            <slot name="header" />
          </div>
        </div>
        <div class="modal-container">
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    doUnmounted: {
      type: Boolean,
      default: true,
    },
  },
  name: 'Modal',
  mounted() {
    document.body.style.overflow = 'hidden'
    // document.addEventListener('keydown', this.keyDownClose)
  },
  beforeUnmount() {
    if (this.doUnmounted) document.body.style.overflow = 'auto'
    // document.removeEventListener('keydown', this.keyDownClose)
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    keyDownClose(event) {
      if (event.code === 'Escape') this.onClose()
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 600px;
  z-index: 1;

  @include desktop {
    @include scrollbar;
  }
  &-container {
    padding: 2.85em;
    @include mobile {
      padding: 1.2em 1em;
    }
  }
  &-header {
    border-bottom: 1px solid #d2d2d2;
    position: relative;
  }

  &-bg {
    background: hsla(0, 0%, 0%, 0.15);
    opacity: 1;
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s;
  }
  &-box {
    background: white;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    border-radius: 1.4em;
    // padding: 2.85em;
    position: relative;
    @include mobile {
      border-radius: 1.4em 1.4em 0 0;
      height: 100%;
      overflow-y: auto;
      // padding: 1.2em 1em 2.85em 1em;
      &::-webkit-scrollbar {
        width: 16px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 6px solid transparent;
        background-color: hsla(0, 0%, 0%, 0.3);
        background-clip: padding-box;
        border-radius: 10px;
      }
    }
    &-wrapper {
      padding: 9vh 0 4vh;
      position: absolute;
      max-width: 820px;
      width: 90%;
      transform: translateX(-50%);
      left: 50%;
      top: 0;
      @include mobile {
        width: 100%;
        padding: 63px 0px 0px 0px;
        bottom: 0;
      }
    }
  }
  .btn-close {
    position: absolute;
    left: 1em;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    // top: 40px;
    // gap: 1em;
    margin-right: 8px;
    svg {
      display: block;
    }
  }
}
</style>
