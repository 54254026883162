import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {
  removeToken,
  removeRefreshToken,
  getRefreshToken,
  getToken,
} from '@/utils/cookie'
import { objectToFormData } from '@/utils/helpers'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || '/',
})

service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error) => {
    const originalRequest = error.config
    // 401 - время жизни токена закончилось, нужно обновить
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response?.data?.code === 'access-denied' &&
      error.config?.url !== '/api/oauth/token'
    ) {
      try {
        originalRequest._retry = true
        await store.dispatch(
          'auth/auth',
          objectToFormData({
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken(),
          })
        )
        originalRequest.headers['Authorization'] = 'Bearer ' + getToken()

        return service(originalRequest)
      } catch (error) {
        console.log(error)
        removeToken() // must remove  token  first
        removeRefreshToken()
        router.push('/')
      }

      return service(originalRequest)
    }

    return Promise.reject(error)
  }
)

export default service
