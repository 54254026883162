import store from '@/store'

export default function useResources() {
  const loadResources = async () => {
    if (Object.keys(store.state.dataset).length) return

    store.commit('setStateProps', { prop: 'isLoading', value: true })

    await Promise.all([
      store.dispatch('dataset/fetchDataset'),
      store.dispatch('auth/getMe'),
      store.dispatch('schemas/getSchemas'),
    ])

    store.commit('setStateProps', { prop: 'isLoading', value: false })
  }

  return {
    loadResources,
  }
}
