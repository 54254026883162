<template>
  <header class="header flex-c-fe">
    <RouterLink to="/applications/list">Заявки</RouterLink>
    <RouterLink to="/applications/list?state=suspended"
      >Приостановленное</RouterLink
    >
    <RouterLink to="/routes/list">Маршруты</RouterLink>
    <!-- <RouterLink to="/segments">Сегменты</RouterLink> -->
    <!-- <button class="header-notify" type="button">
      <img src="@/assets/img/bell-noti.svg" />
    </button> -->
    <div class="header-person flex-c-c">
      <button @click="isMenuShow = !isMenuShow">
        <img src="@/assets/img/person.svg" />
        {{ store.state.auth.me?.name }}
      </button>
      <Dropdown v-if="isMenuShow" @close="isMenuShow = false">
        <li>
          <button @click="onChangePassword">Сменить пароль</button>
        </li>
        <li>
          <button type="button" @click="logout">Выйти</button>
        </li>
      </Dropdown>
    </div>
  </header>
  <Modal v-if="isModalShow" @close="() => changeModalShow(false)">
    <template #header-all>
      <BackButton :click="() => changeModalShow(false)" type="button">
        Отмена
      </BackButton>
    </template>
    <template #content>
      <ChangePasswordForm @success="() => changeModalShow(false)" />
    </template>
  </Modal>
</template>

<script setup>
import router from '@/router'
import { useStore } from 'vuex'
import { ref } from 'vue'
import Dropdown from '@/components/Dropdown.vue'
import Modal from '@/components/Modal.vue'
import BackButton from '@/components/BackButton.vue'
import ChangePasswordForm from '@/components/ChangePasswordForm.vue'

const store = useStore()
const isModalShow = ref(false)
const isMenuShow = ref(false)

/**
 * @param {boolean} payload
 */
const changeModalShow = (payload) => (isModalShow.value = payload)

const onChangePassword = () => {
  isMenuShow.value = false
  isModalShow.value = true
}

const logout = async () => {
  isMenuShow.value = false
  store.commit('auth/clearTokens')
  await router.push({ name: 'Auth' })
  // #3174
  // перезагружаем страницу, что бы сбросить profile, и заново расставить roles
  // реактивно сбросить не получилось, т.к. есть не реактивные roles, которые устанавливаются при первой авторизации
  location.reload()
}
</script>

<style lang="scss" scoped>
.header {
  height: 95px;
  border-bottom: 1px solid #d2d2d2;
  margin: 0px 15px;
  font-size: 10px;
  gap: 16px;
  @include mobile {
    height: 63px;
  }
  &-notify {
    @include reset-button;
    width: 24px;
    height: 24px;
  }
  &-person {
    gap: 6px;
    font-weight: 700;
    position: relative;
    button {
      @include reset-button;
      font-size: inherit;
      line-height: inherit;
    }
    img {
      max-width: 24px;
      max-height: 24px;
      vertical-align: middle;
    }
  }
  &-exit {
    font-size: 1em;
  }
}
</style>
