import {
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
} from '@/utils/cookie'
import { postAuthLogin } from '@/api/auth'
import { getMyself } from '@/api/profile'

/**
 * @returns {{
 * me: Profile
 * }}
 */
const state = () => ({
  me: null,
})

const mutations = {
  setStateProps(state, payload) {
    state[payload.prop] = payload.value
  },
  clearTokens() {
    removeToken()
    removeRefreshToken()
  },
}

const getters = {
  isSales(state) {
    if (!state.me) return
    return state.me.roles.includes('sales')
  },
}

const actions = {
  async auth({ commit }, payload) {
    try {
      commit('setStateProps', { prop: 'status', value: 'loading' })
      const resp = await postAuthLogin(payload)
      setToken(resp.access_token)
      setRefreshToken(resp.refresh_token)

      commit('setStateProps', { prop: 'status', value: 'succeed' })
    } catch (error) {
      commit('setStateProps', { prop: 'status', value: 'error' })
      throw error
    }
  },
  async getMe({ commit, state }) {
    if (state.me) return

    try {
      const me = await getMyself()
      commit('setStateProps', { prop: 'me', value: me })
    } catch (error) {
      throw error
    }
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
