import request from '@/utils/request'

let requestAuth = null

/**
 * @param {FormData} data
 * @returns {access_token: string, refresh_token: string, expires_in: number}
 */
export function postAuthLogin(data) {
  if (!requestAuth) {
    requestAuth = request({
      url: `/api/oauth/token`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    })
  }

  return requestAuth
}
