import { getTimestamp, getStorage, updateStorage } from '@/utils/helpers'

const KEY_DRAFTS = 'drafts'
const KEY_DRAFTS_TRANSACTION = 'drafts_transaction'
const KEY_LAYOUTS = 'layouts'

const state = () => ({
  [KEY_DRAFTS]: getStorage(KEY_DRAFTS) || {},
  [KEY_DRAFTS_TRANSACTION]: getStorage(KEY_DRAFTS_TRANSACTION) || {},
  [KEY_LAYOUTS]: [],
})

const getters = {
  getTransaction: (state) => (id) => {
    return state[KEY_DRAFTS_TRANSACTION][id]
  },
  getLayouts(state) {
    return state[KEY_LAYOUTS]
  },
}

const mutations = {
  addDraft(state, request) {
    const id = getTimestamp()

    state[KEY_DRAFTS][id] = request
    updateStorage(KEY_DRAFTS, state[KEY_DRAFTS])
  },
  updateDraft(state, { id, request }) {
    state[KEY_DRAFTS][id] = request
    updateStorage(KEY_DRAFTS, state[KEY_DRAFTS])
  },
  removeDraft(state, id) {
    delete state[KEY_DRAFTS][id]
    updateStorage(KEY_DRAFTS, state[KEY_DRAFTS])
  },
  updateTransaction(state, { draftId, requestId }) {
    state[KEY_DRAFTS_TRANSACTION][requestId] = draftId
    updateStorage(KEY_DRAFTS_TRANSACTION, state[KEY_DRAFTS_TRANSACTION])
  },
  removeDraftTransaction(state, id) {
    delete state[KEY_DRAFTS_TRANSACTION][id]
    updateStorage(KEY_DRAFTS_TRANSACTION, state[KEY_DRAFTS_TRANSACTION])
  },
  setLayouts(state, layouts) {
    state[KEY_LAYOUTS] = layouts
  },
}

const actions = {}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
