import { getToken } from '@/utils/cookie'
import request from '@/utils/request'
import '@/utils/types.js'

export function passwordChange(data) {
  return request({
    url: `/api/account/password/change/`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    data,
  })
}
