<template>
  <form ref="form">
    <div class="form-item">
      <label for="oldPassword">Старый пароль</label>
      <input id="oldPassword" type="password" v-model="oldPassword" required />
      <span v-if="errors['oldPassword']" class="error">{{
        errors['oldPassword']
      }}</span>
    </div>
    <div class="form-item">
      <label for="newPassword">Новый пароль</label>
      <input id="newPassword" type="password" v-model="newPassword" required />
      <span v-if="errors['newPassword']" class="error">{{
        errors['newPassword']
      }}</span>
    </div>
    <div class="form-item">
      <label for="repeatPassword">Подтверждение нового пароля</label>
      <input
        id="repeatPassword"
        type="password"
        v-model="repeatPassword"
        required
      />
      <span v-if="errors['repeatPassword']" class="error">{{
        errors['repeatPassword']
      }}</span>
    </div>
    <button
      class="btn btn-next"
      type="submit"
      :disabled="isLoading"
      @click="onSubmit"
    >
      Сохранить
    </button>
  </form>
</template>

<script setup>
import { passwordChange } from '@/api/account'
import { requiredText } from '@/constants'
import { useForm } from 'vee-validate'
import { useStore } from 'vuex'
import { ref, defineEmits } from 'vue'
import { object, string, ref as yupRef } from 'yup'

const validationSchema = object({
  oldPassword: string().required(requiredText),
  newPassword: string()
    .required(requiredText)
    .min(8, 'Минимальная длинна 8 символов'),
  repeatPassword: string()
    .required(requiredText)
    .oneOf([yupRef('newPassword')], 'Ваш пароль не совпадает'),
})

const { errors, validate, defineField } = useForm({
  validationSchema,
})

const [oldPassword] = defineField('oldPassword')
const [newPassword] = defineField('newPassword')
const [repeatPassword] = defineField('repeatPassword')
const store = useStore()
const isLoading = ref(false)
const emit = defineEmits(['success'])

/**
 * @param {Event} e
 */
const onSubmit = async (e) => {
  e.preventDefault()

  const { valid } = await validate()
  if (!valid) return

  try {
    isLoading.value = true
    await passwordChange({
      old_password: oldPassword.value,
      new_password: newPassword.value,
      repeat_password: repeatPassword.value,
    })
    emit('success')
    store.dispatch('showSuccess', {
      text: 'Пароль успешно изменен',
      btnText: 'Ок',
      onClick: () => undefined,
    })
  } catch (error) {
    store.dispatch('showError', error)
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.form-item {
  margin-bottom: 10px;
}
.error {
  color: red;
}
</style>
