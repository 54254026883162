import { getDataset } from '@/api/dataset'
import { precacheBanners } from '@/pwa/utils'

/**
 * @returns {Dataset | null}
 */
const state = () => null

const mutations = {
  setDataset(state, data) {
    for (const key in data) {
      state[key] = data[key]
    }
  },
}

const getters = {
  getDataset(state) {
    return state
  },
}

const actions = {
  async fetchDataset({ commit }) {
    try {
      const data = await getDataset()

      if (data?.banners) {
        precacheBanners(data.banners)
      }

      commit('setDataset', data)
    } catch (error) {
      throw new Error(error)
    }
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
